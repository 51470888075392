<template>
<h3>Your Name in English</h3>
<input  type="text"
        class="form-control w-75"
        aria-label="Sizing example input"
        aria-describedby="inputGroup-sizing-sm"
        :value="formDataEn.empNameEn"
        @input="ChangeFormDataEn({empNameEn:$event.target.value})"
        placeholder="Ex: Ahmed Saad"
        name="name"
        autocomplete="on"
/>
</template>
<script>
export default {
  name: 'UserInputEn',
  props:{
      formDataEn: Object,
  },
  methods:{
      ChangeFormDataEn(value){
        console.log(value);
        this.$emit("ChangeFormDataEn",value);
    },
  },
}
</script>


<style>
h3{
  color: #597163;
}

input{
    margin-left: auto;
    margin-right: auto;
}


</style>